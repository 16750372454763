<template>
    <div class="container mx-auto">
        <div class="mt-6 md:mt-16 px-6 md:px-16">

            <h1 class="font-bold text-40px uppercase tracking-wider">Analytics</h1>

            <div class="flex flex-wrap bg-black p-4 md:p-8 -mx-2 mt-8 analytics-wrapper">
                <!-- <div class="w-full sm:w-1/2 p-2">
                    <img src="../assets/images/analytics1.png" class="rounded" />
                </div>
                <div class="w-full sm:w-1/2 p-2">
                    <img src="../assets/images/analytics2.png" class="rounded" />
                </div>
                <div class="w-full sm:w-1/2 p-2">
                    <img src="../assets/images/analytics3.png" class="rounded" />
                </div>
                <div class="w-full sm:w-1/2 p-2">
                    <img src="../assets/images/analytics4.png" class="rounded" />
                </div> -->

                <div class="w-full p-2 mb-4">
                    <div class="max-w-xs">
                        <VueCtkDateTimePicker v-model="dateRange" :range="true" label="Date Range" format="YYYY-MM-DD" formatted="ll" color="#000000" button-color="#000000" :dark="false" :error="dateRangeError" @is-shown="dateRangeError=error=false" :shortcut="'last30Days'" @validate="updateDateRange"></VueCtkDateTimePicker><span class="spinner" v-if="loaded && loading"><span></span><span></span><span></span><span></span></span>
                    </div>
                </div>

                <div class="w-full" v-if="!loaded && loading">
                    <list-loader :width="400" :height="160" :speed="1" :animate="true" primaryColor="lightgray" secondaryColor="gray"></list-loader>
                </div>

                <div class="w-full text-red-600 font-work-sans p-2 mb-2" v-if="error">
                    {{error_message}}
                </div>

                <div class="w-full sm:w-1/2 p-2">
                    <Bar v-if="loaded" :chart-data="barChartData" :chart-options="barChartOptions" chart-id="bar_chart" ref="barChart" :styles="{backgroundColor: '#FFFF', borderRadius: '4px'}" :plugins="chartPlugins" />
                </div>
                <div class="w-full sm:w-1/2 p-2">
                    <LineChart v-if="loaded" :chart-data="lineChartData" :chart-options="lineChartOptions" chart-id="line_chart" ref="lineChart" :styles="{backgroundColor: '#FFFF', borderRadius: '4px'}" :plugins="chartPlugins" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { ListLoader } from "vue-content-loader";
    import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    import { Bar, Line as LineChart } from 'vue-chartjs/legacy'

    import 'chartjs-adapter-moment';

    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, TimeScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, TimeScale)

    //ChartJS.defaults.font.family = "'Work Sans', sans-serif";

    export default {
        name: 'Analytics',
        components: {
            ListLoader,
            VueCtkDateTimePicker,
            Bar,
            LineChart
        },
        data: function() {
            return {
                dateRange: null,
                dateRangeError: false,
                formatted_value: '',
                dataKeys: ['StartViewController', 'ShareViewController', 'retake_selected', 'data_captured'],
                dataLabels: {
                    StartViewController: 'Start Page Trigger - Total',
                    ShareViewController: 'Photos Taken - Total',
                    retake_selected: 'Retake Selected - Total',
                    data_captured: 'Data Captured - Total'
                },
                dataColors: {
                    StartViewController: '#0759c7',
                    ShareViewController: '#24d2ef',
                    retake_selected: '#39d183',
                    data_captured: '#aa4399'
                },
                barChartData: {
                    labels: [],
                    datasets: [{ data: [], backgroundColor: [] }]
                },
                barChartOptions: {
                    responsive: true,
                    aspectRatio: 2,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    layout: {
                        padding: 10
                    },
                    plugins: {
                        title: {
                            display: true,
                            align: 'start',
                            text: 'Event Count by Event Name',
                        },
                        legend: {
                            display: false,
                        }
                    }
                },
                lineChartData: {
                    datasets: []
                },
                lineChartOptions: {
                    responsive: true,
                    aspectRatio: 2,
                    maintainAspectRatio: false,
                    borderWidth: 2,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    layout: {
                        padding: 10
                    },
                    plugins: {
                        title: {
                            display: true,
                            align: 'start',
                            text: 'Event Count by Event Name over time',
                        },
                        legend: {
                            display: true,
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                minUnit: 'day',
                                tooltipFormat: 'MMM DD, YYYY'
                            }
                        }
                    }
                },
                loaded: false,
                loading: true,
                error: false,
                error_message: '',
                data:{},
                chartPlugins: [
                    {
                        afterDraw: chart => {
                            if (chart.data.datasets[0].data.length === 0) {
                                let ctx = chart.ctx;
                                ctx.save();
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                ctx.font = "22px 'Work Sans', sans-serif";
                                ctx.fillStyle = "gray";
                                ctx.fillText('No data available', chart.width / 2, chart.height / 2);
                                ctx.restore();
                            }
                        }
                    }
                ]
            }
        },
        mounted: function() {
            this.getData();
        },
        methods: {
            getData: function() {
                const app = this;
                this.loading = true;
                this.$http({
                    url: 'get-analytics-data',
                    method: 'post',
                    data: {
                        start: this.dateRange.start,
                        end: this.dateRange.end,
                    }
                }).then(function(response) {
                    app.data = response.data;
                    app.barChartData.datasets[0].data.length = 0;
                    app.barChartData.labels.length = 0;
                    app.lineChartData.datasets.length = 0;
                    app.dataKeys.forEach(function(value) {
                        if( typeof response.data.analytics[value] == 'undefined') return;
                        app.barChartData.datasets[0]['data'].push(response.data.analytics[value].total);
                        app.barChartData.labels.push(app.dataLabels[value]);
                        app.barChartData.datasets[0]['backgroundColor'].push(app.dataColors[value]);
                        let lineChartData = {label: '', data: []};
                        lineChartData['label'] = app.dataLabels[value];
                        lineChartData['borderColor'] = app.dataColors[value];
                        lineChartData['backgroundColor'] = app.dataColors[value];
                        response.data.analytics[value].data.forEach(function(dateValue) {
                            lineChartData.data.push({
                                x: dateValue.date,
                                y: dateValue.value
                            });
                        });
                        app.lineChartData.datasets.push(lineChartData);
                    });
                    app.loading = false;
                    app.lineChartOptions.plugins.legend.display = true;
                    if( app.barChartData.datasets[0]['data'].length == 0 ) {
                        app.lineChartData.datasets.push({data:[]});
                        app.lineChartOptions.plugins.legend.display = false;
                        app.$refs.barChart.updateChart();
                        app.$refs.lineChart.updateChart();
                    }
                    app.loaded = true;
                }).catch(function(err) {
                    app.loading = false;
                    if(app.loaded === false) {
                        app.lineChartData.datasets.push({data:[]});
                        app.loaded = true;
                    }
                    if(typeof err.response.data !== 'undefined') {
                        app.error = true;
                        app.error_message = err.response.data.message;
                    }
                })
            },
            updateDateRange: function() {
                this.error = false;
                this.dateRange.start == null ? (this.dateRange.start = this.dateRange.end) : '';
                this.dateRange.end == null ? (this.dateRange.end = this.dateRange.start) : '';
                this.getData();
            }
        }
    }
</script>

<style>
.analytics-wrapper .button-validate button:after {
    display: inline-block;
    content: 'Apply';
    color: #000;
    text-indent: 4px;
    font-weight: bold;
    position: relative;
}
.analytics-wrapper .button-validate button:hover:after {
    color: #FFF;
}
</style>
<style scoped>
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.spinner {
    /* position: absolute;
    top: 25%;
    left: auto;
    right: 25%; */
    position: relative;
    left: 112%;
    top:-32px;
    transition-property: padding, opacity;
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: ease-in, ease;
    transition-delay: 0s, 0.2s;
}
.spinner span {
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0.15rem;
    width: 1.5rem;
    height: 1.5rem;
    opacity: 1;
    border: 3.4px solid #0DE9FF;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0DE9FF transparent transparent transparent;
}
.spinner span:nth-child(1) {
    animation-delay: 0.45s;
}
.spinner span:nth-child(2) {
    animation-delay: 0.3s;
}
.spinner span:nth-child(3) {
    animation-delay: 0.15s;
}
</style>